import { MakerOptions } from 'maker-ui'

export const options: MakerOptions = {
  framework: 'gatsby',
  breakpoints: ['768px', '960px', '1280px'],
  fonts: {
    body: 'Questrial, Helvetica, Arial, sans-serif',
    heading: 'Questrial, Helvetica, Arial, sans-serif',
  },
  colors: {
    light: {
      link: '#fff',
      link_hover: '#FFB751',
      text: '#333',
      background: '#fff',
      primary: '#24518F',
      secondary: '#CF443C',
      accent: '#FFB751',
      muted: '#f6f6f6',
      border: '#e6e6e6',
      bg_topbar: '#355cac',
      bg_header: 'transparent',
      bg_mobileMenu: '#355cac',
      bg_footer: '#153661',
    },
  },
  header: {
    navType: 'reverse',
    mobileNavType: 'basic',
    maxWidth: [500, '100%'],
    sticky: false,
    stickyOnMobile: false,
    showColorButton: false,
    menuOverflow: 'scroll',
    scrollClass: {
      className: 'scroll-test',
      scrollTop: 2000,
    },
    dropdown: {
      transition: 'fade-down',
    },
    bpIndex: 0,
  },
  mobileMenu: {
    width: '72vw',
    transition: 'slide-left',
    closeOnBlur: true,
    showCloseButton: false,
    // visibleOnDesktop: true,
    closeOnRouteChange: true,
  },
  content: {
    maxWidth: '100%',
    maxWidthSection: 800,
    bpIndex: 0,
  },
  footer: {
    maxWidth: ['86vw', 960],
  },
}
