import * as React from 'react'
import { Flex, Link } from 'maker-ui'
import { InstagramIcon, FacebookIcon, TwitterIcon, YouTubeIcon } from './Icons'

const icons = [
  {
    ico: <FacebookIcon css={{ height: 20 }} />,
    url: 'https://www.facebook.com/LUPEActionNJ',
  },
  {
    ico: <InstagramIcon css={{ height: 20 }} />,
    url: 'https://www.instagram.com/lupeactionnj/',
  },
  {
    ico: <TwitterIcon css={{ height: 17 }} />,
    url: 'https://twitter.com/LUPEActionNJ',
  },
]

export const Social = (props) => {
  return (
    <Flex
      {...props}
      css={{
        '&.mobile-icons': {
          a: { border: 'none', background: 'none' },
          svg: {
            transform: 'scale(1.2) ',
          },
          'a:hover': {
            svg: { fill: 'var(--color-accent)' },
          },
        },
      }}>
      {icons.map((item, index) => (
        <Link
          key={index}
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          css={{
            background: 'rgba(0,0,0,0.5)',
            height: 50,
            width: 50,
            borderRadius: '50%',
            display: 'flex',
            marginRight: 15,
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all ease .3s',
            '&:hover': {
              background: '#fff',
              svg: {
                fill: '#000',
              },
            },
            svg: {
              fill: '#fff',
              transition: 'all ease .3s',
            },
          }}>
          {item.ico}
        </Link>
      ))}
    </Flex>
  )
}
