import * as React from 'react'
import {
  Layout,
  Header,
  Navbar,
  MobileMenu,
  Content,
  Main,
  Footer,
  MenuProps,
} from 'maker-ui'
require('typeface-questrial')

import { Logo } from './Logo'
import { Social } from './Social'
import { ArrowIcon } from './Icons'
import { options } from '../config/options'
import { styles } from '../config/styles'

const menu: MenuProps[] = [
  {
    label: 'Website',
    path: 'https://lupeaction.org',
    classes: 'red-btn nav-btn',
    icon: <ArrowIcon />,
  },
  {
    label: 'Donate',
    path: 'https://lupeaction.org/donate/',
    classes: 'donate-btn nav-btn',
    newTab: true,
  },
]

const mobileMenu: MenuProps[] = [
  {
    label: 'LUPE Action Website',
    path: 'https://lupeaction.org',
  },
  {
    label: 'Donate',
    path: 'https://lupeaction.org/donate/',
    newTab: true,
  },
]

export default ({ children }) => (
  <Layout styles={styles} options={options}>
    <Header>
      <Navbar logo={<Logo />} menu={menu} navArea={<Social />} />
      <MobileMenu
        menu={mobileMenu}
        closeButton={false}
        footer={<Social className="mobile-icons" />}
      />
    </Header>
    <Content>
      <Main>{children}</Main>
    </Content>
    <Footer
      css={{
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 30,
      }}>
      Latinas United for Political Empowerment Action
    </Footer>
  </Layout>
)
