import * as React from 'react'
import { Image } from 'maker-ui'

export const Logo = () => {
  return (
    <Image
      src="https://lupeaction.org/wp-content/uploads/2020/10/logo.jpg"
      alt="LUPE Action logo"
      css={{ height: [200, 320], boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.24)' }}
    />
  )
}
