export const styles = {
  a: {
    textDecoration: 'none',
  },
  li: {
    listStyle: 'none',
  },
  '#mobile-menu': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 20px',
  },
  '#mobile-menu li': {
    fontSize: 20,
    marginBottom: 10,
  },
  '#mobile-menu ul': {
    padding: '20vh 0 10vh',
  },
  '.menu-button svg': {
    fill: '#fff',
    height: 36,
  },
  '.menu-area, .nav-area': {
    alignItems: 'flex-start !important',
  },
  '.menu-area a, .menu-area a:hover': { color: '#fff' },
  '.nav-btn': {
    height: 50,
    padding: '0 25px',
    borderRadius: 3,
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.25)',
    transition: 'all ease .3s',
  },
  '.nav-btn span, .nav-btn a, .nav-btn': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.red-btn': {
    background: 'var(--color-secondary)',
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.23)',
    marginRight: 20,
    svg: {
      marginRight: 10,
    },
    '&:hover': {
      transform: 'translateX(-5px)',
      borderColor: '#fff',
    },
  },
  '.donate-btn': {
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.55)',
    textTransform: 'uppercase',
    background: 'var(--color-primary)',
    '&:hover': {
      transform: 'translateX(5px)',
      borderColor: '#fff',
    },
  },
  '.menu-primary': {
    display: 'flex',
  },
  header: {
    padding: '50px 50px 20px',
    position: 'absolute !important',
    width: '100%',
  },
  '#content': {
    visibility: 'visible',
    opacity: 1,
  },
}
